
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  height: var(--header-height);
  padding: 12px 0;
  box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.25);
  /* border-bottom: 1px solid #222F4B; */
  position: relative;
}
  
.logoContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 30px;
  width: 140.5px;

}

.logoName {
  /* color: #222F4B; */
  font-size: 24px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-align: left;
  cursor: pointer;
}

.nav__toggle,
.nav__close {
  font-size: 1.6rem;
  color: #222F4B;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__list__web {
  width: 30%;
  min-width: 365px;
}

.nav__list__web ul{
  display: flex;
  justify-content: space-between;
}

.nav__list__web button {
  display: none;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}

.mobileNavLinks {
  padding: 10% 5%;
  border-bottom: 1px solid #222F4B;
}

#mobileNavButton {
  margin: auto;
}

.nav__link {
  color: #393a3a;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  transition: color 0.4s;
}

.nav__link:hover {
  color: #2764b0;
}

.nav__close {
  position: absolute;
  top: 1.7rem;
  right: 8%;
}

.nav__menu {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  right: 0;
  background-color: #EBF2FF;
  width: 100%;
  height: 100vh;
  padding: 22% 10%;
  transition: transform 0.4s ease, right 0.4s ease;
  transform: translateX(100%);
}

.nav__menu_active {
  transform: translateX(0);
}

@media screen and (max-width: 768px) {

  .navbar {
    justify-content: space-between;
    padding: 5px 0;
    height: 68px;
  }

  .logoContainer{
    margin-left: 8%;
  }

  .logo {
    height: 25px;
    width: 117px;
  }

  .logoName {
    font-size: 20px;
  }
  .nav__toggle {
    margin-right: 8%;
  }

  #navButton {
    display: none;
  }

}

@media screen and (min-width: 769px) {
  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}

