/* Contact Form */

.contactContainer {
    width: 100%;
    padding-top: 8rem;
    padding-bottom: 75px;
    margin: auto;
    text-align: center;
}

.contactPageHeadings {
    color: #2158A4;
    line-height: 60px;
    font-size: 3rem;
    font-weight: 700;
}

.contactForm {
    display: flex;
    flex-direction: column;
    margin: 50px 25%;
    gap: 30px;
}

.formRow {
    display: flex;
    column-gap: 2.5rem;
}

.inputField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
    width: 100%;
}

.inputField label {
    font-size: 1.125rem;
    font-weight: 600;
    color: #393a3a;
}

.inputField input,
textarea {
    outline: none;
    border: 1px solid #393a3a;
    padding: 0.8em 0.6em;
    width: 100%;
    font-size: 1rem;
    color: #393a3a;
    box-sizing: border-box;
}

.inputField input:-webkit-autofill,
textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #393a3a !important;
}

.inputField input:focus,
.inputField textarea:focus {
    box-shadow: 0px 0px 5px 1px #2158A4;
    -webkit-box-shadow: 0px 0px 5px 1px #2158A4;
    -moz-box-shadow: 0px 0px 5px 1px #2158A4;
}

.errorMessage {
    font-size: 1rem;
    color:#2158A4;
    text-align: left;
}

#submitButton {
    width: 150px;
}

.textarea {
    height: 150px;
}


/* Office Location */

.locationSection {
    width: 100%;
    background-color: #EBF2FF;
    margin-bottom: 120px;
}

.container {
    width: 70%;
    box-sizing: border-box;
    margin: auto;
    padding: 50px 0;
}

.locationCard {
    display: flex;
    gap: 0;
    margin-top: 30px;
    height: 300px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.28);
}

.mapCard {
    background-color: rgb(198, 196, 196);
    height: 100%;
    width: 50%;
}

.officeInfoCard {
    background-color: #fff;
    box-sizing: border-box;
    width: 50%;
    padding: 32px;
}

.officeInfoCard h2 {
    font-size: 2.25rem;
    color: #1D254F;
}

.officeInfo {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    gap: 26px;
}

.infoRow {
    display: flex;
    gap: 15px;
    color: #2f4680;
    font-size: 1.125rem;
    align-items: center;
}

.infoIcon {
    font-size: 2rem;
}

@media screen and (max-width: 1200px) {
    .contactForm {
        margin: 50px 15%;
    }
}

@media screen and (max-width: 768px) {
    .contactContainer {
        padding-bottom: 30px;
    }

    .contactPageHeadings {
        font-size: 2.375rem;
    }

    .contactForm {
        margin: 8%;
        gap: 20px;
    }

    .formRow {
        flex-direction: column;
        gap: 30px;
    }

    .inputField input,
    textarea {
        padding: 0.7em;
    }

    .locationSection {
        margin-bottom: 50px;
    }

    .officeInfoCard h2 {
        font-size: 1.5rem;
    }

    .infoRow {
        font-size: 0.875rem;
    }

    .infoIcon {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        width: 90%;
    }

    .locationCard {
        flex-direction: column;
        height: auto;
    }

    .mapCard {
        height: 300px;
        width: 100%;
    }

    .officeInfoCard {
        width: 100%;
    }
}