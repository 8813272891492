.overlay {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
    z-index: 10000;
}

.popup {
    margin: 100px auto;
    padding: 20px;
    background: #fff;
    width: 30%;
    transition: all 5s ease-in-out;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 1rem;
}

.success {
    background-color: #94fbbf;
}

.danger {
    background-color: #f9949c;
}

.popup .close {
    transition: all 200ms;
    font-size: 1.875rem;
    font-weight: bold;
    text-decoration: none;
    background: transparent;
    border: none;
    color: #000;
}

.popup .content {
    max-height: 30%;
    overflow: auto;
    font-size: 1.125rem;
}

@media screen and (max-width: 700px) {
    .box {
        width: 70%;
    }

    .popup {
        width: 70%;
    }

    .popup .content {
        font-size: 1rem;
    }
}