/* Resources Section */

.resourcesSection {
    display: flex;
    flex-direction: column;
    padding: 4% 0px;
}

.resourcesHeading {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    color: #2158A4;
}

.resources {
    display: flex; 
    width: 100%;
    margin: 50px 0 20px;
    padding: 0 5%;
    box-sizing: border-box;
    /* column-gap: 4%; */
}

.rows {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8%;
}

.singleRow {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8%;
}

.resourceCard {
    /* width: 250px; */
    text-align: center;
    box-shadow: 8px 8px 8px 2px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    background-color: white; 
}

.resourceCardImg {
    width: 250px;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: end;
}

.resourceCard h1 {
    padding: 10px 0;
    width: 100%;
    background-color: #fff;
    color: #393a3a;
    font-weight: 500;
    font-size: 27px;
}

.resourceCard:hover {
    transform: scale(1.05); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}


@media screen and (min-width: 769px) and (max-width: 1380px) {
    .resources {
        flex-direction: column;
        row-gap: 75px;
        padding: 0 8%;
    }
    .rows {
        justify-content: space-between;
        gap: 0;
    }
    .singleRow {
        justify-content: space-between;
        padding: 0 6%;
        gap: 0;
    }
    .resourceCardImg  {
        width: 220px;
        height: 220px;
    }
    
}

/* Tablet View */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .resourcesSection {
        padding: 8% 0;
    }
    
    .resources {
        margin: 60px 0 20px;
    }

    .rows {
        gap: 8%;
    }
    .singleRow {
        padding: 0;
        gap: 0;
    }

    .resourceCardImg  {
        width: 200px;
        height: 200px;
    }
}



/* Mobile View */

@media screen and (max-width: 768px) {
    .resourcesSection {
        padding: 8% 8% 16%;
    }

    .resourcesHeading {
        font-size: 34px;
    }

    .resources {
        flex-direction: column;
        gap: 48px;
        padding: 0;
        margin: 50px 0 10px;
    }

    .rows {
        flex-direction: column;
        row-gap: 48px;
    }

    .singleRow {
        flex-direction: column;
        row-gap: 48px;
        padding: 0;
    }
    .resourceCard {
        /* width: 70%; */
        box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -webkit-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
    }

    .resourceCardImg {
        width: 250px;
        height: 250px;
    }
    
    .resourceCard h1 {
        font-size: 22px;
        padding: 15px;
    }
}

@media screen and (min-width: 540px) and (max-width: 768px) {
    .resourceCardImg {
        width: 300px;
        height: 300px;
    }
    .resourceCard h1 {
        font-size: 24px;
    }
}