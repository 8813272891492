/* Description Banner and animation */

.banner {
    width: 100%;
    padding-top: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description {
    font-weight: 700;
    line-height: 65px;
    text-align: center;
    color: #393a3a;
}

.description h1 {
    font-size: 48px;
}

.description p {
    font-size: 36px;
}

.description p span {
    color: #2158A4;
}


.demoContainer {
    margin-top: 3rem;
    height: 250px;
    width: 100%;
    background-color: #cecece36;
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.demoContainer h1 {
    font-size: 1.75rem;
    font-weight: 600;
    color: #393a3a;
    text-align: center;
}

#homeButton {
    margin-top: 46px;
    background-color: #2158A4;
}

.animation {
    width: 45%;
    height: 45%;
    margin: 3% auto 5%;
    text-align: center;
    box-shadow: 10px 10px 12px 4px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: 10px 10px 12px 4px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 10px 10px 12px 4px rgba(0, 0, 0, 0.28);
}



/* Tablet View of Description Banner and animation*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .description {
        margin: 0 20px;
    }
    .animation {
        width: 64%;
        height: 64%;
        margin: 8% auto;
    }
}

/* Mobile view of Description Banner and animation*/
@media screen and (max-width: 768px) {
    .banner {
        padding-top: 140px;
    }
    .description {
        line-height: 56px;
        margin: 0 8%;
    }
    .description h1 {
        font-size: 34px;
    }
    
    .description p {
        margin-top: 10px;
        font-size: 20px;
        line-height: 34px;
    }

    .animation {
        width: 64%;
        height: 64%;
        margin: 60px auto;
        box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -webkit-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
    }
    .demoContainer {
        padding: 0 8%;
        margin-top: 8%;
    }
    .demoContainer h1 {
        font-size: 1.25rem;
    }
    #homeButton {
        margin-top: 36px;
    }
}

@media screen and (max-width: 520px) {
    .animation {
        width: 84%;
        height: 84%;
        margin: 60px auto;
        box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -webkit-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
    }
}