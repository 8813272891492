.Documentation-page {
    min-height: 100vh;
}

/* .hero-section {
    padding: 5rem;
    text-align: center;
    color: #2764b0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 4rem;
}

.Documentation-page .hero-text h1 {
    font-size: 3rem;
    text-align: center;
    color: #2F4682;
    font-weight: 700;
}

hr {
    border: 0;
    height: 2px;
    width: 50%;
    display: block;
    margin: auto;
    background-image: linear-gradient(to right, rgba(230, 223, 223, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
} */

.Documentation-list {
    /* border: 1px solid red ; */
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    gap: 2rem;
    margin-bottom: 4rem;
}

.card {
    width: 22rem;
    cursor: pointer;
}

.card-body h6 {
    color: #222F4B;
    font-weight: 700;
}

.div {
    display: flex;
    width: 90vw;
    justify-content: center;
    align-items: center;
    padding: 6%;
}

@media screen and (max-width:1183px) {
    .Documentation-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:777px) {
    .Documentation-list {
        grid-template-columns: 1fr;
    }

    .card {
        width: 100%;
    }
}