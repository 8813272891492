.hero-section {
    /* border: 1px solid red; */
    padding: 4rem 8% 3rem; 
    text-align: center;
    color: #2764b0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
}

.hero-section div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.hero-text h1 {
    font-size: 3rem;
    text-align: center;
    color: #2158A4;
    font-weight: 700;
}

hr {
    border: 0;
    height: 2px;
    width: 50%;
    display: block;
    margin: auto;
    background-image: linear-gradient(to right, rgba(230, 223, 223, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.search-bar {
    /* border: 1px solid green; */
    color: #222F4B;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.search-bar-input input {
    outline: none;
    padding: 15px;
    background-color: rgb(236, 234, 234);
    width: 50%;
    border: none;
}

.search-btn {
    background-color: #2764b0;
    padding: 15px;
    border: none;
    cursor: pointer;
    color: white;
}

.search-btn:hover {
    background-color: #222F4B;
    transition: all 0.5s ease-in-out;
}

.blog-hero {
    display: flex;
    justify-content: space-around;
    height: 100%;
    padding: 0 8% 4%;
    gap: 3rem;
}

.box-1-card {
    overflow: hidden;
    border: 1px solid #222f4b3f;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: white;
}

.box-1-card-img {
    width: 100%;
    object-fit: cover;
    height: 15.5rem;
    /* border: 1px solid green; */
}

.box-1-card-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 1rem 1rem;
    color: #393a3a;
}


.box-2-card {
    overflow: hidden;
    display: flex;
    gap: 1rem;
    margin-bottom: 3rem;
    border-radius: 10px;
    border: 1px solid #222f4b3f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: white;
}

.box-2-card:hover,
.box-1-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.box-2-card-img {
    width: 45%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.box-2-card:hover .box-2-card-img {
    transform: scale(1.1);
}

.box-2-card-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 1rem;
    width: 50%;
    transition: transform 0.3s ease, color 0.3s ease;
    color: #393a3a;
}

.box-2-card-text h2 {
    font-size: 1.5rem;
    margin: 0;
    transition: color 0.3s ease;
}

.box-2-card-text p {
    font-size: 1rem;
    margin: 0;
    transition: color 0.3s ease;
}

.box-1,
.box-2 {
    width: 50%;
}

.blog-list {
    /* border: 1px solid red; */
    padding: 0 8% 4%;
}

.heading {
    text-transform: uppercase;
    color: #2158A4;
}

.blog-container {
    /* border: 10px solid green; */
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}

.filtered {
    padding: 0 8%;
}

.blog-container-card {
    overflow: hidden;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #222f4b3f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: white;
    cursor: pointer;
}

.blog-container-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-container-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: transform 0.3s ease;
}

.blog-container-card:hover img {
    transform: scale(1.1);
}

.card-text {
    margin-top: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: transform 0.3s ease, color 0.3s ease;
    width: 100%;
    color: #393a3a;
}


.card-text h2 {
    font-size: 1.5rem;
    margin: 0;
    transition: color 0.3s ease;
}

.card-text p {
    font-size: 1rem;
    margin: 0;
    transition: color 0.3s ease;
}


/* Media Queries */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .blog-hero {
        padding: 0 16% 6%;
    }

    .search-bar-input input {
        width: 80%;
    }
}

@media screen and (max-width: 1024px) {
    .blog-container {
        gap: 3rem;
    }

    .blog-hero {
        flex-direction: column;
    }

    .box-1 {
        width: 100%;
    }

    .box-2 {
        width: 100%;
    }

    .blog-container {
        grid-template-columns: 1fr 1fr;
    }

    .blog-container-card {
        width: 100%;
    }

}


@media screen and (max-width: 769px) {
    .hero-text h1 {
        font-size: 2.375rem;
    }

    .blog-hero h2 {
        font-size: 20px;
    }

    .blog-hero p {
        font-size: 14px;
    }

    .blog-container h2 {
        font-size: 20px;
    }

    .blog-container p {
        font-size: 14px;
    }
    .search-bar-input input {
        width: 100%;
    }

    .blog-container {
        grid-template-columns: 1fr;
    }

    .blog-container-card {
        width: 100%;
    }

    .blog-list {
        padding: 0 8% 16%;
    }

}

@media screen and (max-width: 597px) {
    .hero-section {
        width: 100%;
        align-items: center;
    }

    .blog-container {
        flex-direction: column;
    }

    .search-bar {
        width: 100%;
    }
}

@media screen and (max-width: 462px) {
    .box-2-card {
        flex-direction: column;
    }

    .box-2-card-img{
        width: 100%;
    }

    .box-2-card-text {
        width: 91%;
    }

    .search-btn {
        border-radius: 5px 5px 5px 5px;
        width: 80%;
        margin-top: 10px;
    }

}