.video-container {
    margin-top: 5rem;
    width: 100%;
    padding: 3rem 8% 5rem;
}

.demo-videos {
    width: 100%;
    height: 100%;
}

.demo-videos h3 {
    text-align: center;
    color: #2158A4;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2.75rem;
}

.demo-videos-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3.75rem;
}

.video {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    border-radius: 10px;
    border: 1px solid #cecece;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #cecece09;
}

.video iframe {
    width: 100%;
}

.video p {
    color: #393a3a;
    padding: 1.5rem 1rem;
    font-weight: 600;
    font-size: 1.125rem;
}

.video:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width:1200px) {
    .demo-videos-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:767px) {
    .demo-videos-list {
        grid-template-columns: 1fr;
        gap: 2.5rem;
    }

    .demo-videos h3 {
        font-size: 2.375rem;
    }

    .video iframe {
        height: 195px;
    }

    .video p {
        padding: 1rem;
    }
}