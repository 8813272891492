.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    text-align: center;
}

.error {
    font-size: 22px;
    color: #2f4680;
    padding: 35px;
    font-weight: 500;
    border-bottom: 1px solid #222F4B;
    border-top: 1px solid #222F4B;
    margin: 5%;
}

@media screen and (max-width: 768px) {
    .error {
        padding: 15px;
        font-size: 16px;
    }
}