.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.logoContainer {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.logo {
    display: flex;
    gap: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 115px;
}

.logoImage {
    height: 30px;
    width: 140.5px;
}

.logoText {
    color: #393a3a;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-align: left;
}

.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.coloredContainer {
    background-color: #cecece36;;
    width: 100%;
    margin-bottom: 30px;
}

.infoCard {
    margin: 0 15%;
    padding: 50px 0;
}

.infoCard h1 {
    font-size: 2.25rem;
    line-height: 54px;
    font-weight: 700;
    color: #393a3a;
}

.infoCard p {
    margin-top: 30px;
    font-size: 1.25rem;
    line-height: 32px;
    color: #2158A4;
}

.demoFormContainer {
    text-align: center;
}

.demoPageHeadings {
    color: #2158A4;
    font-size: 2.25rem;
    font-weight: 700;
}

#submitButton {
    width: 180px;
    margin-top: 20px;
    margin-bottom: 30px;
}


@media screen and (max-width: 768px) {
    .logoContainer {
        height: 70px;
    }

    .logo {
        margin-left: 8%;
    }

    .logoImage {
        height: 25px;
        width: 117px;
    }

    .logoText {
        font-size: 1.125rem;
    }

    .infoCard {
        margin: 0 8%;
        padding: 30px 0;
    }

    .infoCard h1 {
        font-size: 1.625rem;
        line-height: 38px;
    }

    .infoCard p {
        font-size: 1rem;
        line-height: 26px;
    }

    .demoPageHeadings {
        font-size: 1.625rem;
    }

    .dropdownInput {
        padding: 10px;
    }
}