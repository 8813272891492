.footerContainer {
    min-width: 100%;
    background-color: #2158A4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.footer, .websiteLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer {
    border-top: 1px solid #cecece;
    width: 100%;
    padding: 0.8rem 10%;
}

.websiteLinks {
    align-items: flex-start;
    padding: 1.3rem 0;
    width: 80%;
}

.footer p, .weblink, .dropdownButton {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}

.dropdownContent .weblink {
    font-size: 16px;
    padding-left: 1rem;
}

.resourcesLinks {
    display: inline-block;
}

.dropdownButton {
    background: none;
    text-decoration: none;
    border: none;
    cursor: pointer;
    padding-right: 3rem;
}

.dropdownButtonIcon {
    font-size: 2rem;
}

.dropdownContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.6rem;
    min-width: 180px;
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
}

.show {
    
    opacity: 1;
    max-height: 140px;
}

.weblink {
    transition: transform 0.3s ease;
}

.socialLinks {
    display: flex;
    gap: 40px;
}

.socialLinks a {
    font-size: 32px;
    color: #fff;
    transition: transform 0.3s ease;
}

.footerContainer a:hover {
    transform: scale(1.05);
}

@media screen and (min-width: 769px) and (max-width: 901px) {
    .websiteLinks {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {

    .footer, .websiteLinks {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;
    }

    .websiteLinks {
        flex-direction: column;
        gap: 14px;
    }

    .footer p, .weblink,
    .dropdownButton{
        font-size: 16px;
    }

    .dropdownContent .weblink {
        font-size: 14px;
    }
}