.blogContainer {
    margin: 10% 0;
    padding: 0 20%;
    width: 100%;
    text-align: justify;
}

.blogPost {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
}

.blogPost h1 {
    font-size: 3rem;
    font-weight: 700;
    text-transform:none;
    color: #2158A4;
    text-align: center;
}

.publication {
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: 5rem;
}

.publication div {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    color: #393a3a;
    font-size: 1.2rem;
}

.linebreak {
    width: 100%;
}

.markdown img {
    height: 100%;
    width: 100%;
    margin: 2rem auto;
}

.markdown p, .markdown h1, .markdown h2, .markdown h3, .markdown h4,
.markdown ul,
.markdown ol {
    line-height: 30px;
    margin-bottom: 1.5rem;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
    font-weight: 700;
    color: #2158A4;
}

.markdown h1 {
    font-size: 2rem;
    font-family: mainHeading;
}

.markdown h2 {
    font-size: 1.75rem;
    font-family: subHeading;
}

.markdown h3 {
    font-size: 1.5rem;
}

.markdown p, .markdown ul,
.markdown ol {
    font-size: 1.125rem;
    color: #393a3a;
    font-family: para;
}

.prevNextButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .blogContainer {
        margin: 16% 0 10%;
        padding: 0 14%;
    }
}

@media screen and (max-width: 769px) {
    .blogContainer {
        margin: 120px 0 8%;
        padding: 0 6%;
    }

    .blogPost {
        row-gap: 1.275rem;
    }

    .blogPost h1 {
        font-size: 2.275rem;
        line-height: 3rem;
    }

    .publication {
        flex-direction: column;
        row-gap: 0.275rem;
    }

    .publication div {
        font-size: 1rem;
    }

    .markdown img {
        margin: 1.125rem auto;
    }

    .markdown h1 {
        font-size: 1.75rem;
    }

    .markdown h2 {
        font-size: 1.5rem;
    }

    .markdown h3 {
        font-size: 1.25rem;
    }
    .markdown p, .markdown ul,
    .markdown ol {
        font-size: 1rem;
    }
}


.shareLinks {
    position: fixed;
    z-index: 100;
    display: flex;
    gap: 1.125rem;
    padding: 10px;
    border-radius: 5px;
}

.shareLinks a {
    color: #2158A4;
    font-size: 1.85rem;
    transition: transform 0.3s ease;
}

.shareLinks a:hover {
    transform: scale(1.1);
}

@media (min-width: 769px) {
    .shareLinks {
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        flex-direction: column;
    }
}

@media (max-width: 769px) {
    .shareLinks {
        position: static;
        flex-direction: row;
        justify-content: center;
        gap: 1.75rem;
    }
}