/* Eaxee Tools Description */

.mainTools {
    display: flex;
    flex-direction: column;
    padding: 4% 18% 0;
    box-sizing: border-box
}


.mainToolsHeading {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    color: #2158A4;
    margin-bottom: 10px;
}

.toolDetails {
    display: flex;
    justify-content: space-between;
    gap: 5%;
    margin-top: 90px;
}

.toolDetails:first-of-type {
    margin-top: 50px;
}

.reversed {
    flex-direction: row-reverse;
}

.toolDescription {
    width: 45%;
}

.toolDescription h1 {
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
    color: #393a3a;
}

.toolDescription p {
    color: #393a3a;
    font-weight: 400;
    font-size: 1rem;
    margin: 20px 0;
    line-height: 24px;
}

.toolDescription span {
    font-weight: 600;
    color: #2158A4;
}

.toolDetails img {
    box-shadow: 10px 10px 12px 4px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: 10px 10px 12px 4px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 10px 10px 12px 4px rgba(0, 0, 0, 0.28);
}

#tool1Img {
    height: 50%;
    width: 50%;
}

.tool2Images {
    position: relative;
    width: 500px;
    height: 380px;
}

#tool2Img1 {
    height: 220px;
    width: 380px;
    position: absolute;
    top: 0;
    left: 0;
}

#tool2Img2 {
    height: 225px;
    width: 390px;
    position: absolute;
    bottom: 0;
    right: 0;
}

#tool3Img {
    width: 50%;
    height: 100%;
}

#tool4Img {
    width: 50%;
    height: 100%;
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
    .mainTools {
        padding: 5% 6% 0%;
    }
    #tool1Img {
        width: 45%;
    }
    #tool3Img {
        width: 50%;
        height: 280px;
    }

    #tool4Img {
        width: 50%;
        height: 280px;
    }
}


@media screen and (max-width: 1023px) {
    .toolDetails {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .reversed {
        flex-direction: none;
    }

    .toolDescription,
    #tool1Img,
    .tool2Images,
    #tool3Img,
    #tool4Img {
        width: 100%;
    }
}

@media screen and (min-width: 521px) and (max-width: 769px) {
    .toolDescription,  #tool1Img, .tool2Images, #tool3Img, #tool4Img{
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .mainTools {
        padding: 0 8%;
    }

    .mainToolsHeading  {
        font-size: 2.125rem;
        margin-bottom: 0;
    }

    .toolDetails {
        margin-top: 60px;
    }
    
    .toolDescription h1 {
        font-size: 1.625rem;
    }

    .toolDescription p {
        margin: 10px 0;
    }

    .toolDescription {
        width: 100%;
    }

    .toolDetails img {
        box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -webkit-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 5px 5px 6px 2px rgba(0, 0, 0, 0.28);
    }

    .tool2Images {
        position: static;
        height: 100%;
    }

    #tool2Img2 {
        display: none;
    }

    #tool2Img1 {
        position: static;
        width: 100%;
        height: 100%;
    }
}

