.Whitepaper-page {
    min-height: 100vh;
}

/* .hero-section {
    padding: 5rem;
    text-align: center;
    color: #2764b0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 4rem;
}

.Whitepaper-page .hero-text h1 {
    font-size: 3rem;
    text-align: center;
    color: #2F4682;
    font-weight: 700;
}

hr {
    border: 0;
    height: 2px;
    width: 50%;
    display: block;
    margin: auto;
    background-image: linear-gradient(to right, rgba(230, 223, 223, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
} */

.whitepaper-list {
    /* border: 1px solid red; */
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    gap: 2rem;
}

.whitepaper-card {
    width: 18rem;
    border: 1px solid #222f4b3f;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: white;
}

.card-body {
    padding: 10px;
}

.whitepaper-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.div {
    /* border: 1px solid red; */
    display: flex;
    width: 90vw;
    justify-content: center;
    align-items: center;
    text-align: justify;
    padding: 6%;
}

@media screen and (max-width: 956px) {
    .whitepaper-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 644px) {
    .whitepaper-list {
        grid-template-columns: 1fr;
    }

    .whitepaper-card {
        width: 100%;
    }

}