.Event-page {
    min-height: 100vh;
}
/* .hero-section {
    padding: 5rem;
    text-align: center;
    color: #2764b0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    margin-top: 4rem;
}

.hero-section div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.Event-page .hero-text h1 {
    font-size: 3rem;
    text-align: center;
    color: #2F4682;
    font-weight: 700;
}

hr {
    border: 0;
    height: 2px;
    width: 50%;
    display: block;
    margin: auto;
    background-image: linear-gradient(to right, rgba(230, 223, 223, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
} */

.Event-hero {
    /* border: 1px solid red; */
    padding: 10px;
}

.current-event span {
    color: #222F4B;
    font-weight: 800;
}

.current-event center {
    margin-bottom: 2rem;
}

.current-event-list {
    /* border: 1px solid red; */
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    gap: 20px;
}

.card {
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: white;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.div {
    display: flex;
    flex-direction: row;
    width: 90vw;
    justify-content: center;
    align-items: center;
    padding: 6%;
}

/* Media Queries */
@media screen and (max-width:768px) {
    .current-event-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 597px) {

    .current-event-list {
        grid-template-columns: 1fr;
    }

    .card {
        width: 100%;
    }
}