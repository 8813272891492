@font-face {
  font-family: 'mainHeading';
  src: url('../src/fonts/Futura\ Heavy\ font.ttf')
}
@font-face {
  font-family: 'subHeading';
  src: url('../src/fonts/futura\ medium\ bt.ttf')
}
@font-face {
  font-family: 'para';
  src: url('../src/fonts/Lato-Regular.ttf')
}

.mainHeading{
  font-family: mainHeading;
}
.subHeading{
  font-family: subHeading;
}
.para{
  font-family: para;
}

html,
body {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  scroll-behavior: smooth;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: unset;
}

p {
  margin-bottom: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: unset;
  line-height: unset;
  color: unset;
}

dl,
ol,
ul 
{
  padding: 0;
  margin: 0;
}

.button {
  box-sizing: border-box;
  background-color: #2158A4;
  border: none;
  color: #fff;
  padding: 16px 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px #2764b0;
}

@media screen and (max-width: 767px) {
  .button {
    padding: 14px 16px;
    font-size: 16px;
  }
}

