/* Features and Benefits */

.featuresBanner {
    width: 100%;
    /* background-color: #EBF2FF; */
    padding-top: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featuresBannerHeading {
    text-align: center;
    padding: 0 6%;
}

.featuresBannerHeading h1 {
    color: #2158A4;
    font-size: 3rem;
    font-weight: 700;
}

.featuresBannerHeading p {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 18px 0 40px;
    color: #393a3a;
}

.features {
    display: flex;
    justify-content: space-evenly;
}

.featureDescription {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 23%;
}

.featureDescription h2 {
    font-size: 1.75rem;
    font-weight: 700;
    text-align: left;
    color: #2158A4;
}

.featureDescription ul li {
    list-style-type: disc;
    font-size: 1.125rem;
    color: #393a3a;
    padding-top: 30px;
    line-height: 24px;
}


.whyeaxee {
    background-color: #cecece36;
    padding: 3rem 0;
}
.whyeaxee h1{
    color: #393a3a;
}
/* Tablet + Mobiles */
@media screen and (max-width: 1023px) {
    .whyeaxee {
        padding: 3rem 10%;
    }
    
    .features {
        width: 100%;
        flex-direction: column;
        row-gap: 10px;
    }

    .featureDescription {
        width: 100%;
    }

    .featureDescription ul li {
        padding-top: 20px;
    }
}


/* Tablet View */
@media screen and (min-width: 769px) and (max-width: 1023px) {
    .featuresBanner {
        padding: 8% 0;
    }

}


/* Mobile View */
@media screen and (max-width: 768px) {
    
    .whyeaxee {
        padding: 3rem 8%;
    }

    .featuresBanner {
        padding: 8% 0;
    }
    
    .featuresBannerHeading  {
        padding: 0 8%;
    }

    .featuresBannerHeading h1 {
        font-size: 2.125rem;
        line-height: 44px;
    }

    .featuresBannerHeading p {
        font-size: 1.125rem;
        margin: 14px 0 32px;
        line-height: 28px;
    }

    .featureDescription h2 {
        font-size: 1.625rem;
        line-height: 36px;
    }

    .featureDescription ul li {
        font-size: 1rem;
    }
}